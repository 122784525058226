<template>
  <b-form-group
    label="Vites Tipi"
    label-for="gear"
  >
    <validation-provider
      #default="{ errors }"
      name="Vites Tipi"
      rules="required"
    >
      <v-select
        id="gear"
        v-model="dataItem.id_com_gear"
        :options="gears"
        label="title"
        :reduce="gears => gears.id"
        placeholder="Vites Tipi"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'

export default {
  name: 'Gears',
  components: {
    BFormGroup,
    ValidationProvider,
    vSelect,
  },
  computed: {
    dataItem() {
      return this.$store.getters['stock/addLogoStock']
    },
    gears() {
      return this.$store.getters['gears/getGears']
    },
  },
  created() {
    this.getGears()
  },
  methods: {
    getGears() {
      this.$store.dispatch('gears/gearsList')
    },
  },
}
</script>
