<template>
  <b-form-group
    label="Satış Şartı"
    label-for="sale_price"
  >
    <validation-provider
      #default="{ errors }"
      name="Satış Şartı"
      rules="required"
    >
      <cleave
        id="sale_price"
        v-model="dataItem.sale_price"
        class="form-control"
        :raw="true"
        :options="options.price"
        placeholder="Satış Şartı"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import Cleave from 'vue-cleave-component'

export default {
  name: 'SalePrice',
  components: {
    BFormGroup,
    ValidationProvider,
    Cleave,
  },
  data() {
    return {
      options: {
        price: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['stock/addLogoStock']
    },
  },
}
</script>
