<template>
  <b-form-group
    label="Alım Tutarı"
    label-for="purchase_price"
  >
    <validation-provider
      #default="{ errors }"
      name="Alım Tutarı"
      rules="required"
    >
      <cleave
        id="purchase_price"
        v-model="dataItem.purchase_price"
        class="form-control"
        :raw="true"
        :options="options.price"
        placeholder="Alım Tutarı"
        :readonly="isReadonly"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import Cleave from 'vue-cleave-component'

export default {
  name: 'PurchasePrice',
  components: {
    BFormGroup,
    ValidationProvider,
    Cleave,
  },
  props: {
    isReadonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: {
        price: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['stock/addLogoStock']
    },
  },
}
</script>
