<template>
  <b-form-group
    label="Özel Stok"
    label-for="special_stock"
  >
    <validation-provider
      #default="{ errors }"
      name="Özel Stok"
      rules="required"
    >
      <v-select
        id="special_stock"
        v-model="dataItem.special_stock"
        :options="pool"
        label="title"
        :reduce="item => item.id"
        placeholder="Özel Stok"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'

export default {
  name: 'SpecialStock',
  components: {
    BFormGroup,
    ValidationProvider,
    vSelect,
  },
  data() {
    return {
      pool: [
        { id: 1, title: 'Evet' },
        { id: 0, title: 'Hayır' },
      ],
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['stock/addLogoStock']
    },
  },
}
</script>
