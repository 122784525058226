<template>
  <b-form-group
    label="Konum"
    label-for="state"
  >
    <validation-provider
      #default="{ errors }"
      name="Konum"
      rules="required"
    >
      <v-select
        id="state"
        v-model="dataItem.state"
        :options="state"
        label="title"
        :reduce="state => state.id"
        placeholder="Konum"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'

export default {
  name: 'State',
  components: {
    BFormGroup,
    ValidationProvider,
    vSelect,
  },
  data() {
    return {
      state: [
        { id: '1', title: 'Stok Sahası' },
        { id: '2', title: 'Showroom' },
      ],
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['stock/addLogoStock']
    },
  },
}
</script>
