<template>
  <b-form-group
    label="Motor No"
    label-for="engine"
  >
    <validation-provider
      #default="{ errors }"
      name="Motor No"
      rules="required"
    >
      <b-form-input
        id="engine"
        v-model="dataItem.engine"
        placeholder="Motor No"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'Engine',
  components: {
    BFormGroup,
    BFormInput,
    ValidationProvider,
  },
  computed: {
    dataItem() {
      return this.$store.getters['stock/addLogoStock']
    },
  },
}
</script>
