<template>
  <b-form-group
    label="Stok Durumu"
    label-for="id_com_stock_statuses"
  >
    <validation-provider
      #default="{ errors }"
      name="Stok Durumu"
      rules="required"
    >
      <v-select
        id="id_com_stock_statuses"
        v-model="dataItem.id_com_stock_statuses"
        :options="dataList"
        label="title"
        :reduce="item => item.id"
        placeholder="Stok Durumu"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'

export default {
  name: 'StockStatuses',
  components: {
    BFormGroup,
    ValidationProvider,
    vSelect,
  },
  computed: {
    dataItem() {
      return this.$store.getters['stock/addLogoStock']
    },
    dataList() {
      return this.$store.getters['stockStatuses/getDataList']
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('stockStatuses/dataList')
    },
  },
}
</script>
